import axios from "axios";
import { categorizeDevicePerformance } from "./deviceGate.js";
import init, { is_valid_solution } from "./drillx/pkg/drillx_wasm.js";

// Custom logging function that logs only in development environment
export function devLog(...args) {
  if (process.env.VUE_APP_ENVIRONMENT === "development") {
    console.log(...args);
  }
}

// Add a new function to emit status updates
function emitStatus(status) {
  window.dispatchEvent(new CustomEvent("miningStatus", { detail: status }));
}

export async function getChallenge(apiKey) {
  emitStatus("Fetching challenge");
  // const userId = sessionStorage.getItem("userId");
  // const groupId = sessionStorage.getItem("groupId");
  const captchaWorkerId = sessionStorage.getItem("captchaWorkerId");
  const params = new URLSearchParams();
  // if (userId) {
  //   params.append("userId", userId);
  //   devLog(`[getChallenge] Using userId: ${userId}`);
  // } else if (groupId) {
  //   params.append("groupId", groupId);
  //   devLog(`[getChallenge] Using groupId: ${groupId}`);
  // } else {
  //   // params.append("groupId", "1");
  //   devLog(`[getChallenge] No userId or groupId found`);
  // }
  if (captchaWorkerId) {
    params.append("captchaWorkerId", captchaWorkerId);
    devLog(`[getChallenge] Using captchaWorkerId: ${captchaWorkerId}`);
  }

  try {
    devLog("[getChallenge] Sending request to backend to fetch challenge");
    devLog("[getChallenge] API URL:", `${process.env.VUE_APP_BACKEND_API_URL}`);
    devLog("[getChallenge] API Key:", apiKey);
    devLog("[getChallenge] Params:", params);
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/captcha/challenge?${params}`,
      {
        headers: { "X-API-KEY": apiKey },
      }
    );
    devLog("[getChallenge] Response:", response.data);
    devLog("[getChallenge] Challenge fetched successfully");
    if (response.data.captchaWorkerId) {
      sessionStorage.setItem("captchaWorkerId", response.data.captchaWorkerId);
      devLog(
        `[getChallenge] Stored captchaWorkerId: ${response.data.captchaWorkerId}`
      );
    }
    return response.data;
  } catch (error) {
    console.error("[getChallenge] Error fetching challenge:", error);
    emitStatus("Error fetching challenge");
    await new Promise((resolve) => setTimeout(resolve, 30000));
    return null;
  }
}

export async function submitSolution(apiKey, solution, challenge) {
  emitStatus(`Submitting best solution. Difficulty ${solution.difficulty}`);
  try {
    await init();
    devLog("[submitSolution] WASM module initialized");
    devLog("[submitSolution] Challenge:", challenge);
    devLog("[submitSolution] Solution:", solution);

    const challengeArray = new Uint8Array(
      atob(challenge)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    const solutionArray = new Uint8Array([
      ...solution.digest,
      ...solution.nonce,
    ]);

    if (challengeArray.length !== 32 || solutionArray.length !== 24) {
      console.error("[submitSolution] Invalid input lengths");
      devLog(challengeArray.length, solutionArray.length);
      return null;
    }

    let isValid;
    try {
      isValid = is_valid_solution(challengeArray, solutionArray);
    } catch (error) {
      console.error("[submitSolution] Error validating solution:", error);
      return null;
    }

    if (!isValid) {
      console.error("[submitSolution] Invalid solution, not submitting");
      return null;
    }
    devLog("[submitSolution] Solution is valid");

    const userId = sessionStorage.getItem("userId");
    const groupId = sessionStorage.getItem("groupId");
    const captchaWorkerId = sessionStorage.getItem("captchaWorkerId");
    const params = new URLSearchParams();
    if (userId) {
      params.append("userId", userId);
      devLog(`[submitSolution] Using userId: ${userId}`);
    } else if (groupId) {
      params.append("groupId", groupId);
      devLog(`[submitSolution] Using groupId: ${groupId}`);
    } else {
      params.append("groupId", "1");
      devLog(
        `[submitSolution] No userId or groupId found. Defaulting to groupId: 1`
      );
    }
    if (captchaWorkerId) {
      params.append("captchaWorkerId", captchaWorkerId);
      devLog(`[submitSolution] Using captchaWorkerId: ${captchaWorkerId}`);
    }

    devLog("[submitSolution] Submitting solution to backend");
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_API_URL}/captcha/solution?${params}`,
      solution,
      {
        headers: {
          "X-API-KEY": apiKey,
          "Content-Type": "application/json",
        },
      }
    );
    devLog("[submitSolution] Solution submitted successfully");
    emitStatus("Solution submitted successfully");
    return response.data;
  } catch (error) {
    console.error("[submitSolution] Error in submitSolution:", error);
    emitStatus("Error submitting solution");
    return null;
  }
}

let shouldContinueSolving = true;
export function startSolving(apiKey) {
  devLog("[startSolving] Starting captcha process");
  shouldContinueSolving = true;
  solveLoop(apiKey);
}

export function stopSolving() {
  devLog("[stopSolving] Stopping captcha process");
  shouldContinueSolving = false;
}

async function solveLoop(apiKey) {
  let loopsCompleted = 0;
  let runIndefinitely = true;
  const { category } = await categorizeDevicePerformance();
  if (category >= 3) {
    runIndefinitely = false;
  }
  while (runIndefinitely && shouldContinueSolving) {
    if (loopsCompleted === 0) {
      // sleep for 5 seconds to ensure the site is loaded
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }

    try {
      const challengeData = await getChallenge(apiKey);
      if (!challengeData) {
        console.error(
          "[solveLoop] Failed to get challenge, retrying in 30 seconds"
        );
        emitStatus("Failed to get challenge, retrying in 30 seconds");
        await new Promise((resolve) => setTimeout(resolve, 30000));
        continue;
      } else if (challengeData.status === "Challenge not ready") {
        devLog(
          `[solveLoop] Challenge not ready, retrying in ${challengeData.retryDelay} seconds`
        );
        emitStatus(
          `Challenge not ready, retrying in ${challengeData.retryDelay} seconds`
        );
        await new Promise((resolve) =>
          setTimeout(resolve, challengeData.retryDelay)
        );
        continue;
      }

      if (!shouldContinueSolving) break;

      devLog(
        "[solveLoop] Got new challenge, starting worker. challenge:",
        challengeData
      );
      emitStatus("Starting solution search");

      // Parse deadline and nextCheckIn
      const deadline = new Date(challengeData.deadline);
      const nextCheckIn = new Date(challengeData.nextCheckIn);

      devLog("[solveLoop] Deadline:", deadline);
      devLog("[solveLoop] Next Check-in:", nextCheckIn);

      if (isNaN(deadline.getTime()) || isNaN(nextCheckIn.getTime())) {
        console.error("[solveLoop] Invalid deadline or nextCheckIn time");
        emitStatus("Invalid deadline or nextCheckIn time");
        await new Promise((resolve) => setTimeout(resolve, 5000));
        continue;
      }

      const solution = await runWorker(challengeData, deadline);

      if (solution) {
        devLog("[solveLoop] Submitting solution", solution);
        const submissionResult = await submitSolution(
          apiKey,
          solution,
          challengeData.challenge
        );
        devLog("[solveLoop] Solution submitted, result:", submissionResult);
      } else {
        devLog("[solveLoop] No solution found or solving completed");
        emitStatus("No solution found or solving completed");
      }

      if (!shouldContinueSolving) break;

      // Wait until the next check-in time before getting a new challenge
      const nextCheckInTime = new Date(challengeData.nextCheckIn);
      const now = new Date();
      const timeToWait = Math.max(0, nextCheckInTime.getTime() - now.getTime());

      devLog(
        `[solveLoop] Waiting ${timeToWait}ms until next check-in time: ${nextCheckInTime}`
      );
      emitStatus(
        `Waiting ${Math.round(timeToWait / 1000)} seconds until next check-in`
      );
      await new Promise((resolve) => setTimeout(resolve, timeToWait));
    } catch (error) {
      console.error("[solveLoop] Error in solving loop:", error);
      emitStatus("Error in solving loop");
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
    loopsCompleted++;
  }
}

function runWorker(challengeData, deadline) {
  return new Promise((resolve) => {
    const worker = new Worker(new URL("./worker.js", import.meta.url), {
      type: "module",
    });

    worker.postMessage(challengeData);
    devLog("[runWorker] Challenge data sent to the worker");

    const workDuration = deadline.getTime() - Date.now();
    devLog(`[runWorker] Solve duration set to ${workDuration}ms`);

    const timeoutId = setTimeout(() => {
      devLog("[runWorker] Solve duration reached, terminating worker");
      worker.terminate();
      resolve(null);
    }, workDuration);

    worker.onmessage = function (event) {
      if (event.data.type === "status") {
        emitStatus(event.data.status);
        // Dispatch a custom event for status updates
        window.dispatchEvent(
          new CustomEvent("workerUpdate", { detail: event.data })
        );
      } else if (event.data.type === "solution") {
        clearTimeout(timeoutId);
        devLog("[runWorker] Received solution from the worker");
        // Dispatch a custom event for solutions
        window.dispatchEvent(
          new CustomEvent("workerUpdate", { detail: event.data })
        );
        worker.terminate();
        resolve(event.data.solution);
      }
    };

    worker.onerror = function (error) {
      clearTimeout(timeoutId);
      console.error("[runWorker] Error in worker:", error);
      emitStatus("Error in worker");
      worker.terminate();
      resolve(null);
    };
  });
}
