import BlogPost from "@/components/blog/BlogPost.vue";
import siteConfig from "@/siteConfig.json"; // Import the siteConfig.json file

const routes = [
  siteConfig.pagesEnabled.home && {
    name: "Home",
    path: "/",
    component: () => {
      const templateNumber = siteConfig.home.template || "1";
      return import(`@/pages/home/${templateNumber}.HomeTemplate`)
        .then((module) => module.default)
        .catch(() => {
          console.warn(
            `Template ${templateNumber}.HomeTemplate not found, falling back to 1.HomeTemplate.`
          );
          return import("@/pages/home/1.HomeTemplate").then(
            (module) => module.default
          );
        });
    },
  },
  siteConfig.pagesEnabled.login && {
    path: "/login",
    name: "Login",
    component: require("@/pages/LoginPage").default,
  },
  siteConfig.pagesEnabled.login && {
    path: "/verify-email",
    name: "Verify Email",
    component: require("@/pages/VerifyEmailPage").default,
  },
  siteConfig.pagesEnabled.login && {
    path: "/verify-email-confirmation",
    name: "Email Verified",
    component: require("@/pages/VerifyEmailConfirmationPage").default,
  },
  siteConfig.pagesEnabled.login && {
    path: "/reset-password",
    name: "Reset Password",
    component: require("@/pages/ResetPasswordPage").default,
  },
  siteConfig.pagesEnabled.login && {
    path: "/welcome",
    name: "Welcome",
    component: require("@/pages/WelcomePage").default,
  },
  siteConfig.pagesEnabled.profile && {
    path: "/profile",
    name: "Profile",
    component: require("@/pages/UserProfilePage").default,
  },
  siteConfig.pagesEnabled.settings && {
    path: "/settings",
    name: "Settings",
    component: require("@/pages/SettingsPage").default,
  },
  // siteConfig.pagesEnabled.dashboard && {
  //   path: "/dashboard/:tool?",
  //   name: "Dashboard",
  //   component: require("@/pages/DashboardPage").default,
  //   props: true,
  // },
  siteConfig.pagesEnabled.dashboard && {
    name: "Dashboard",
    path: "/dashboard/:tool?", // Include the optional tool parameter here
    component: () => {
      const templateNumber = siteConfig.dashboard.template || "1";
      return import(`@/pages/dashboard/${templateNumber}.DashboardTemplate`)
        .then((module) => module.default)
        .catch(() => {
          console.warn(
            `Template ${templateNumber}.DashboardTemplate not found, falling back to 1.DashboardTemplate.`
          );
          return import("@/pages/dashboard/1.DashboardTemplate").then(
            (module) => module.default
          );
        });
    },
    props: true, // Ensure props are passed to the component
  },
  // siteConfig.pagesEnabled.about && {
  //   name: "About",
  //   path: "/about",
  //   component: require("@/pages/AboutPage").default,
  // },
  siteConfig.pagesEnabled.about && {
    name: "About",
    path: "/about",
    component: () => {
      const templateNumber = siteConfig.about.template || "1";
      return import(`@/pages/about/${templateNumber}.AboutTemplate`)
        .then((module) => module.default)
        .catch(() => {
          console.warn(
            `Template ${templateNumber}.AboutTemplate not found, falling back to 1.AboutTemplate.`
          );
          return import("@/pages/about/1.AboutTemplate").then(
            (module) => module.default
          );
        });
    },
  },
  siteConfig.pagesEnabled.pricing && {
    name: "Pricing",
    path: "/pricing",
    component: () => {
      const templateNumber = siteConfig.pricing.template || "1";
      return import(`@/pages/pricing/${templateNumber}.PricingTemplate`)
        .then((module) => module.default)
        .catch(() => {
          console.warn(
            `Template ${templateNumber}.PricingTemplate not found, falling back to 1.PricingTemplate.`
          );
          return import("@/pages/pricing/1.PricingTemplate").then(
            (module) => module.default
          );
        });
    },
  },
  siteConfig.pagesEnabled.faq && {
    name: "FAQ",
    path: "/faq",
    component: require("@/pages/FaqPage").default,
  },
  siteConfig.pagesEnabled.support && {
    name: "Support",
    path: "/support",
    component: require("@/pages/SupportPage").default,
  },
  siteConfig.pagesEnabled.learn && {
    name: "Learn",
    path: "/learn",
    component: require("@/pages/LearnPage").default,
  },
  siteConfig.pagesEnabled.learn && {
    name: "LearnPath",
    path: "/learn/:pathId",
    component: require("@/pages/LearnPage").default,
    props: true,
  },
  siteConfig.pagesEnabled.airdrops && {
    name: "Airdrops",
    path: "/airdrops",
    component: require("@/pages/AirdropsPage").default,
  },
  siteConfig.pagesEnabled.directory && {
    name: "Directory",
    path: "/directory",
    component: require("@/pages/DirectoryPage").default,
  },
  siteConfig.pagesEnabled.mineUI && {
    name: "Solver UI",
    path: "/solver",
    component: require("@/pages/MinePage").default,
  },
  siteConfig.pagesEnabled.swap && {
    name: "Swap",
    path: "/swap",
    component: require("@/pages/TokenSwapPage").default,
  },
  siteConfig.pagesEnabled.swap && {
    name: "Governance Token Swap",
    path: "/buy-governance-tokens",
    component: require("@/pages/GovernanceTokenSwapPage").default,
  },
  siteConfig.pagesEnabled.swap && {
    name: "Meme Coin Swap",
    path: "/buy-meme-coins",
    component: require("@/pages/MemeCoinSwapPage").default,
  },
  siteConfig.pagesEnabled.swap && {
    name: "LST Swap",
    path: "/buy-liquid-staking-tokens",
    component: require("@/pages/LiquidStakingSwapPage").default,
  },
  siteConfig.pagesEnabled.blog && {
    name: "Blog",
    path: "/blog",
    component: require("@/pages/BlogPage").default,
  },
  siteConfig.pagesEnabled.disclaimer && {
    name: "Disclaimer",
    path: "/disclaimer",
    component: require("@/pages/DisclaimerPage").default,
  },
  siteConfig.pagesEnabled.privacy && {
    name: "Privacy Policy",
    path: "/privacy-policy",
    component: require("@/pages/PrivacyPolicyPage").default,
  },
  {
    path: "/blog/:slug",
    name: "BlogPost",
    component: BlogPost,
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: require("@/pages/NotFoundPage").default,
  },
].filter(Boolean); // Filter out any null or undefined routes

export default routes;
