<template>
  <div class="max-w-2xl mx-auto mt-20 md:mt-42 w-full px-4">
    <h1
      class="text-4xl lg:text-6xl font-bold mb-12 text-primary-content text-center"
    >
      Test Solver
    </h1>

    <StatusBox
      class="mb-6"
      :status="status"
      :nonces-checked="noncesChecked"
      :nonces-per-second="noncesPerSecond"
    />

    <div class="bg-base-200 p-4 rounded-lg shadow-md mb-6">
      <h2 class="text-lg font-semibold mb-2">Current Best Hash Found</h2>
      <p class="font-mono text-base-content break-all">{{ currentBestHash }}</p>
    </div>

    <div class="bg-base-200 p-4 rounded-lg shadow-md mb-6">
      <h2 class="text-lg font-semibold mb-2">API Key</h2>
      <input
        v-model="apiKey"
        type="text"
        class="input input-bordered w-full"
        placeholder="Enter API Key"
      />
    </div>

    <button
      @click="toggleMining"
      class="w-full px-4 py-2 text-base-content rounded transition duration-300"
      :class="{
        'bg-primary hover:bg-primary-focus': !miningStore.isMining,
        'bg-neutral hover:bg-neutral-focus': miningStore.isMining,
      }"
    >
      {{ miningStore.isMining ? "Stop Mining" : "Start Mining" }}
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from "vue";
import { useSessionStore } from "@/stores/session";
import { useMiningStore } from "@/utilities/ore/store";
import StatusBox from "@/components/mineUI/StatusBox";

const sessionStore = useSessionStore();
const miningStore = useMiningStore();
const apiKey = ref("");
const currentBestHash = ref("No hash found yet");
const status = ref("Idle");
const noncesChecked = ref(0);
const noncesPerSecond = ref(0);

onMounted(() => {
  apiKey.value =
    sessionStore.user?.apiKey || process.env.VUE_APP_X_API_KEY || "";
  window.addEventListener("workerUpdate", handleWorkerUpdate);
});

watch(apiKey, (newApiKey) => {
  if (sessionStore.user) {
    sessionStore.user.apiKey = newApiKey;
  }
});

const handleWorkerUpdate = (event) => {
  const data = event.detail;
  if (data.type === "solution" && data.solution) {
    currentBestHash.value = data.solution.hash.join("");
  } else if (data.type === "status") {
    status.value = data.status;
    if (data.noncesChecked !== undefined) {
      noncesChecked.value = data.noncesChecked;
    }
    if (data.noncesPerSecond !== undefined) {
      noncesPerSecond.value = data.noncesPerSecond;
    }
  }
};

const toggleMining = () => {
  if (miningStore.isMining) {
    miningStore.stopMining();
    // Reset values when stopping
    status.value = "Idle";
    noncesChecked.value = 0;
    noncesPerSecond.value = 0;
    currentBestHash.value = "No hash found yet";
  } else {
    miningStore.startMining(apiKey.value);
  }
};

onUnmounted(() => {
  window.removeEventListener("workerUpdate", handleWorkerUpdate);
});
</script>
