// blogStore.js
import { defineStore } from "pinia";
import axios from "axios";
import siteConfig from "@/siteConfig.json";

const CACHE_EXPIRATION = 60 * 60 * 1000;

export const useBlogStore = defineStore("blog", {
  state: () => ({
    blogPosts: [],
    categories: [],
    selectedKnowledgeLevel: "All", // Default initialization
    isLoading: false,
    error: null,
  }),
  getters: {
    getPostBySlug: (state) => (slug) => {
      return state.blogPosts.find((post) => post.Slug === slug);
    },
  },
  actions: {
    initializeStore() {
      // Initialize categories from siteConfig
      this.categories = siteConfig.blogs.categories || [];
      // Initialize activeCategories with all categories
      this.activeCategories = new Set(this.categories);
    },
    async fetchBlogPosts() {
      const maxRetries = 5; // Maximum number of retry attempts
      const retryDelay = 300; // Delay between retries in milliseconds
      const limit = 60; // Number of posts per request
      let offset = 0; // Offset for pagination
      let allBlogPosts = []; // To store all fetched blog posts
      let morePostsAvailable = true; // Flag to track if more posts need to be fetched

      // Helper function to delay execution
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      // Reset state before fetching
      this.isLoading = true;
      this.error = null;

      // Helper function to fetch blog posts with retries
      const fetchBatch = async (offset, limit, attempt = 1) => {
        for (; attempt <= maxRetries; attempt++) {
          try {
            const response = await axios.get(
              `${process.env.VUE_APP_BACKEND_API_URL}/blogs?offset=${offset}&limit=${limit}`,
              {
                headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
              }
            );
            await delay(5);

            return response.data; // Return data if successful
          } catch (error) {
            console.error(`Error fetching batch (Attempt ${attempt}):`, error);

            if (
              error.response &&
              error.response.status === 503 &&
              attempt < maxRetries
            ) {
              // If it's a 503 error and not the last attempt, wait before retrying
              await delay(retryDelay);
            } else {
              // If it's not a 503 error or it's the last attempt, throw the error
              throw new Error(
                error.message || "An error occurred while fetching blog posts"
              );
            }
          }
        }
      };

      // Loop to fetch all posts in batches
      try {
        while (morePostsAvailable) {
          const batch = await fetchBatch(offset, limit);

          if (batch && batch.length > 0) {
            allBlogPosts = allBlogPosts.concat(batch);
            offset += limit; // Increase offset for the next batch
          } else {
            morePostsAvailable = false; // No more posts to fetch
          }
        }

        // Update state with fetched data
        this.blogPosts = allBlogPosts;
        this.isLoading = false;
      } catch (error) {
        this.error =
          error.message || "An error occurred while fetching blog posts";
        this.isLoading = false;
        console.error("Failed to fetch blog posts after multiple attempts");
      }
    },
    // toggleCategory(category) {
    //   if (this.activeCategories.has(category)) {
    //     this.activeCategories.delete(category);
    //   } else {
    //     this.activeCategories.add(category);
    //   }
    //   // Since Sets are not reactive by default in Vue 3, trigger reactivity:
    //   // this.activeCategories = new Set(this.activeCategories);
    // },
    async fetchBlogPostBySlug(slug, maxRetries = 10) {
      this.isLoading = true;
      this.error = null;

      // Check cache first
      const cachedPost = this.getCachedPost(slug);
      if (cachedPost) {
        this.isLoading = false;
        return cachedPost;
      }

      for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
          const response = await axios.get(
            `${process.env.VUE_APP_BACKEND_API_URL}/blogs?slug=${slug}`,
            {
              headers: { "X-API-KEY": process.env.VUE_APP_X_API_KEY },
            }
          );

          this.isLoading = false;

          if (response.data && Object.keys(response.data).length > 0) {
            console.log("Fetched blog post:", response.data);
            this.cachePost(slug, response.data);
            return response.data;
          } else {
            console.error("Blog post not found for slug:", slug);
            return null;
          }
        } catch (error) {
          console.error(`Attempt ${attempt + 1} failed:`, error);
          if (attempt === maxRetries - 1) {
            this.error =
              error.message || "An error occurred while fetching the blog post";
            this.isLoading = false;
            throw error;
          }
          // Wait before retrying (you can implement exponential backoff here)
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    },

    setActiveCategories(categories) {
      if (categories === "All") {
        //console.log("setting to all categories");
        this.activeCategories = new Set(this.categories);
        //console.log("activeCategories:", this.activeCategories);
      } else {
        this.activeCategories = new Set([categories]);
      }
    },
    setKnowledgeLevel(level) {
      // Mapping textual representations to numeric values
      const levelMap = {
        All: "All",
        Beginner: 1,
        Intermediate: 2,
        Advanced: 3,
      };

      // Update the state with the numeric value
      this.selectedKnowledgeLevel = levelMap[level];
    },
    getCachedPost(slug) {
      const cachedData = localStorage.getItem(`blog_${slug}`);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRATION) {
          return data;
        } else {
          localStorage.removeItem(`blog_${slug}`);
        }
      }
      return null;
    },
    cachePost(slug, data) {
      localStorage.setItem(
        `blog_${slug}`,
        JSON.stringify({
          data,
          timestamp: Date.now(),
        })
      );
    },
  },
});
