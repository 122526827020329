import { defineStore } from "pinia";
import { ref } from "vue";
import { startSolving, stopSolving } from "@/utilities/ore/solver";

export const useMiningStore = defineStore("mining", () => {
  const isMining = ref(false);
  const currentApiKey = ref("");

  function startMining(apiKey) {
    if (!isMining.value) {
      isMining.value = true;
      currentApiKey.value = apiKey;
      startSolving(apiKey);
    }
  }

  function stopMining() {
    if (isMining.value) {
      isMining.value = false;
      stopSolving();
    }
  }

  return { isMining, currentApiKey, startMining, stopMining };
});
